<template>

  <div>

    <header class="vff-header">
      <div class="f-container">
        <a style="text-decoration: none;" href="https://www.google.com" v-on:click.prevent="this.$refs.flowform.reset()"><svg width="300" height="50" viewBox="0 0 300 50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <text id="PPG" xml:space="preserve" x="64" y="49" text-anchor="middle" font-family="Arial Black" font-size="58" font-weight="700" fill="#41b883">PPG</text>
          <text id="Privacy-Policy-Generator" xml:space="preserve" x="205" y="47" text-anchor="middle" font-family="Arial Black" font-size="11" font-weight="700" fill="#41b883" text-decoration="underline">Privacy Policy Generator</text>
          <text id="Bta" xml:space="preserve"><tspan x="143" y="8" text-anchor="middle" font-family="Arial Black" font-size="11" font-weight="700" fill="#41b883" xml:space="preserve">(</tspan><tspan font-family="Arial Black" font-size="11" font-weight="700" fill="#ed230d" xml:space="preserve">Bêta</tspan><tspan font-family="Arial Black" font-size="11" font-weight="700" fill="#41b883" xml:space="preserve">)</tspan></text>
        </svg></a>

      </div>
    </header>

    <div v-if="editor && show_ppg">
      <button @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        bold
      </button>
      <button @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        italic
      </button>
      <button @click="editor.chain().focus().toggleStrike().run()" :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        strike
      </button>
      <button @click="editor.chain().focus().toggleCode().run()" :disabled="!editor.can().chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
        code
      </button>
      <button @click="editor.chain().focus().unsetAllMarks().run()">
        clear marks
      </button>
      <button @click="editor.chain().focus().clearNodes().run()">
        clear nodes
      </button>
      <button @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
        paragraph
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        h1
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        h2
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        h3
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
        h4
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
        h5
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
        h6
      </button>
      <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        bullet list
      </button>
      <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        ordered list
      </button>
      <button @click="editor.chain().focus().toggleHighlight().run()" :class="{ 'is-active': editor.isActive('highlight') }">
        highlight
      </button>
      <button @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
        left
      </button>
      <button @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
        center
      </button>
      <button @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
        right
      </button>
      <button @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
        justify
      </button>
      <button @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
        code block
      </button>
      <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
        blockquote
      </button>
      <button @click="editor.chain().focus().setHorizontalRule().run()">
        horizontal rule
      </button>
      <button @click="editor.chain().focus().setHardBreak().run()">
        hard break
      </button>
      <button @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
        undo
      </button>
      <button @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
        redo
      </button>
    </div>



    <!-- <flow-form v-bind:questions="questions" v-bind:language="language" /> -->
    <flow-form ref="flowform" v-on:complete="onComplete" v-on:submit="onSubmit" v-bind:questions="questions"
               v-bind:language="language" v-bind:standalone="true">


      <!-- test button -->
      <button @click="saveHTML" v-if="show_ppg">Show HTML</button>


      <!-- oho test Tiptap -->
      <div class="text-success">
      </div>
      <!-- Tiptap end -->


      <!-- Custom content for the Complete/Submit screen slots in the FlowForm component -->
      <!-- We've overriden the default "complete" slot content -->
      <template v-slot:complete>
        <div class="f-section-wrap" v-if="!submitted">
          <p>
            <span class="fh2">Ready ?</span>
            <span class="f-section-text">
              A Privacy Policy page is about to be generated for your application. You can review your answers (bottom right up and down
              arrows) or press submit to continue.
            </span>
          </p>
          <p class="f-description"><i>Note: No data will be saved on this prototype API.</i></p>
        </div>
      </template>

      <!-- We've overriden the default "completeButton" slot content -->
      <template v-slot:completeButton>
        <div class="f-submit" v-if="!submitted">
          <button class="o-btn-action" ref="button" type="submit" href="#" v-on:click.prevent="onSendData()"
                  aria-label="Press to submit">
            <span>{{ language.submitText }}</span>
          </button>
          <a class="f-enter-desc" href="#" v-on:click.prevent="onSendData()"
             v-html="language.formatString(language.pressEnter)">
          </a>
        </div>

        <!-- oho -->
        <div class="box" v-if="!editor && submitted"><img v-bind:src="require('@/assets/loading.gif')" height="200" width="200"></div>
        <!-- <div class="text-success" v-if="show_ppg" v-html="editor"></div> -->
        <div class="vff">
          <!-- <div class="f-container"> -->
          <!-- <div class="f-form-wrap"> -->
          <!-- <div class="vff-animate f-fade-in-up field-submittype"> -->
          <div class="text-success" v-if="show_ppg"><editor-content :editor="editor"/></div>
          <!-- </div> -->
          <!-- </div> -->
          <!-- </div> -->
        </div>

      </template>


    </flow-form>

  </div>
</template>

<script>

// Import necessary components and classes
import { FlowForm, QuestionModel, QuestionType, ChoiceOption, LinkOption } from '@ditdot-dev/vue-flow-form'

// Test OHO Tiptap
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import TextAlign from '@tiptap/extension-text-align'
import { Editor, EditorContent } from '@tiptap/vue-3'
import LanguageModel from "@/models/LanguageModel";

export default {
  name: 'OhoPPG',
  components: {
    FlowForm,
    EditorContent
  },
  // all props for Tiptap
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  // en test Tiptap
  data() {
    return {
      language: new LanguageModel({
        // Your language definitions here (optional).
        // You can leave out this prop if you want to use the default definitions.
      }),
      questions: [
        // QuestionModel array
        /*
        new QuestionModel({
          id: 'template',
          tagline: 'Pickup a template for your generated Privacy Policy (Branded, non branded, Orange authentication, etc.)',
          title: 'Choose your template',
          helpTextShow: false,
          type: QuestionType.MultipleChoice,
          multiple: false,
          allowOther: false,
          required: true,
          options: [
            new ChoiceOption({
              label: 'Orange branded using Orange authentication system (using Orange credentials)'
            }),
            new ChoiceOption({
              label: 'Orange branded without authentication'
            }),
            new ChoiceOption({
              label: 'Orange branded with dedicated authentication (not using Orange credentials)'
             }),
            new ChoiceOption({
              label: 'Non Orange branded (with dedicated non Orange credentials)'
            }),
            new ChoiceOption({
              label: 'Non Orange branded (without authentication)'
            })
          ]
        })
        */
        new QuestionModel({
          id: 'language',
          tagline: 'Choose the language of generated document (so far only French ...)',
          title: 'Language',
          helpTextShow: true,
          type: QuestionType.MultipleChoice,
          multiple: false,
          allowOther: false,
          required: true,
          options: [
            new ChoiceOption({
              label: 'fr'
            })
          ]
        }),
        new QuestionModel({
          id: 'applicationName',
          tagline: 'Provide with the name of the application as it will appear on the Store',
          title: 'Application name',
          helpTextShow: false,
          type: QuestionType.Text,
          required: true,
          placeholder: 'Ex: "Ma Super App"'
        }),
        new QuestionModel({
          id: 'companyName',
          tagline: 'Friendly name of the company (for instance "Orange" in stead of "Orange SA")',
          title: 'Company friendly name',
          helpTextShow: false,
          type: QuestionType.Text,
          required: true,
          placeholder: 'Ex: "Orange"'
        }),
        new QuestionModel({
          id: 'companyHeadQuarterAddress',
          tagline: 'Head Quarter address of your company which is Editor of this app',
          title: 'Company Head Quarter address',
          helpTextShow: true,
          description: 'This item could only be given by your legal contact',
          descriptionLink: [
            new LinkOption({
              url: 'https://legal.shp.com.intraorange/fr/protect_donnees_perso_gdpr_dpo/Documents/Reseaux_referents-RODP-vFRrec0721.pdf',
              text: 'See list of your RODP contacts', // optional, default is link url
              target: '_blank' // optional, default is '_blank'
            })
          ],
          type: QuestionType.LongText,
          required: true,
          placeholder: ''
        }),
        new QuestionModel({
          id: 'publicationDirector',
          tagline: 'Publication Director is legally responsible for your app content (most of the time it\'s your Company President ...)',
          title: 'Publication Director',
          helpTextShow: false,
          description: 'This item could only be given by your legal contact',
          descriptionLink: [
            new LinkOption({
              url: 'https://legal.shp.com.intraorange/fr/protect_donnees_perso_gdpr_dpo/Documents/Reseaux_referents-RODP-vFRrec0721.pdf',
              text: 'See list of your RODP contacts', // optional, default is link url
              target: '_blank' // optional, default is '_blank'
            })
          ],
          type: QuestionType.Text,
          required: true,
          placeholder: 'Christel Heydemann'
        }),
        new QuestionModel({
          id: 'publicationDirectorPosition',
          tagline: 'Formal company title of the Publication Director',
          title: 'Publication Director Title',
          helpTextShow: false,
          description: 'This item could only be given by your legal contact',
          descriptionLink: [
            new LinkOption({
              url: 'https://legal.shp.com.intraorange/fr/protect_donnees_perso_gdpr_dpo/Documents/Reseaux_referents-RODP-vFRrec0721.pdf',
              text: 'See list of your RODP contacts', // optional, default is link url
              target: '_blank' // optional, default is '_blank'
            })
          ],
          type: QuestionType.Text,
          required: true,
          placeholder: 'Président Directeur Général'
        }),
        new QuestionModel({
          id: 'applicationDescription',
          tagline: 'Describe here a short description of what your application is doing...',
          title: 'Application description',
          helpTextShow: true,
          description: 'Ex: The application will help you do this and this ... Bla bla bla and will let you do other great stuffs. In addition the app is targeting all users (Orange or non Orange).',
          descriptionLink: [
            new LinkOption({
              url: '#',
              text: 'See some expample of other applications description', // optional, default is link url
              target: '_blank' // optional, default is '_blank'
            })
          ],
          type: QuestionType.LongText,
          required: true,
          placeholder: ''
        }),
        new QuestionModel({
          id: 'historyDurationForDataCollection',
          tagline: 'You should comply with regulation rules about holding personal data in time. Make sure you get this information from your service team. This need to be accurate',
          title: 'How long collected data will be kept',
          helpTextShow: true,
          description: 'Example: 3 mois',
          descriptionLink: [
            new LinkOption({
              url: '#',
              text: 'See more infro from DJ on your legal constrains and best practices...', // optional, default is link url
              target: '_blank' // optional, default is '_blank'
            })
          ],
          type: QuestionType.Text,
          required: true,
          placeholder: ''
        }),
        new QuestionModel({
          id: 'dpoEmailAddress',
          tagline: 'Email contact that would handle specific request about personnal data management',
          title: 'DPO Email address',
          helpTextShow: true,
          description: 'This email address should be valid and regulary watched. Any request made to this address would have a the legal obligation to be treated in less than 30 days!',
          descriptionLink: [
            new LinkOption({
              url: '#',
              text: 'See more infro from DJ on your legal constrains and best practices...', // optional, default is link url
              target: '_blank' // optional, default is '_blank'
            })
          ],
          type: QuestionType.Email,
          required: true,
          placeholder: ''
        }),
        new QuestionModel({
          id: 'companyAdministrativeName',
          tagline: 'Full formal company administrative name (ex: Orange SA)',
          title: 'Company administrative name',
          helpTextShow: false,
          description: 'The full company name as it appears on formal documents (ex: "Egyption Company for Mobile Services S.A.E." in stead of "Orange Egypt")',
          descriptionLink: [
            new LinkOption({
              url: '#',
              text: 'See more infro from DJ on your legal constrains and best practices...', // optional, default is link url
              target: '_blank' // optional, default is '_blank'
            })
          ],
          type: QuestionType.Text,
          required: true,
          placeholder: ''
        }),
      ],
      editor: null, // Tiptap
      show_ppg: null
    }
  },
  // For Tiptap
  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },
  // end for TipTap

  mounted() {
    document.addEventListener('keyup', this.onKeyListener)
    // for tiptap
    this.editor = new Editor({
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Highlight,
      ],
      content: this.modelValue,
      //content: null,
      autofocus: false,
      editable: true,
      injectCSS: false,
      onUpdate: () => {
        // HTML
        this.$emit('update:modelValue', this.editor.getHTML())

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
    })
    // Enf Tiptap
  },

  beforeUnmount() {
    document.removeEventListener('keyup', this.onKeyListener)
    this.editor.destroy() //Tiptap
  },

  methods: {
    onKeyListener($event) {
      // We've overriden the default "complete" slot so
      // we need to implement the "keyup" listener manually.

      if ($event.key === 'Enter' && this.completed && !this.submitted) {
        this.onSendData()
      }
    },

    /* eslint-disable-next-line no-unused-vars */
    onComplete(completed, questionList) {
      // This method is called whenever the "completed" status is changed.
      this.completed = completed
    },

    /* eslint-disable-next-line no-unused-vars */
    onSubmit(questionList) {
      // This method will only be called if you don't override the
      // completeButton slot.
      this.onSendData()
    },

    onSendData() {
      // Set `submitted` to true so the form knows not to allow back/forward
      // navigation anymore.
      this.$refs.flowform.submitted = true

      this.submitted = true

      /* eslint-disable-next-line no-unused-vars */
      const data = this.getJsonData()
      console.log(process.env.VUE_APP_END_POINT_URL + "/create/html")
      const url = process.env.VUE_APP_END_POINT_URL + "/create/html"
      post(url, data)
        .then(data => {
          this.editor.commands.setContent(data.data, false)
          this.show_ppg = true
        })
        .catch(error => {
          error.message; // 'An error has occurred: 404'
        });
    },

    getJsonData() {
      const JsonData = {
      }

      this.questions.forEach(question => {
        if (question.id) {
          JsonData[question.id] = question.answer
        }
      })

      return JsonData
    },

    getData() {
      // TODO: Remove this foncton as it's not used
      const data = {
        questions: [],
        answers: []
      }

      this.questions.forEach(question => {
        if (question.title) {
          let answer = question.answer
          if (Array.isArray(answer)) {
            answer = answer.join(', ')
          }

          data.questions.push(question.title)
          data.answers.push(answer)
        }
      })

      return data
    },

    saveHTML() {
      console.log(this.editor.getHTML())
      // alert(this.editor.getHTML())
      const jsonData = {'html': this.editor.getHTML()}
      console.log(process.env.VUE_APP_END_POINT_URL + "/save/html")
      const url = process.env.VUE_APP_END_POINT_URL + "/save/html"
      post(url, jsonData)
        .then(data => {
          //this.editor.commands.setContent(data.data, false)
          //this.show_ppg = true
          alert(data.url)
        })
        .catch(error => {
          error.message; // 'An error has occurred: 404'
        });
    }
  }
}

async function post(url, payload) {
  const response = await fetch(url, {
    'method': 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/html'
    },
    body: JSON.stringify(payload),
  })
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  let response_json = await response.json()
  return response_json
}

</script>



<style lang="css">
@import '@/assets/css/themes/theme-minimal.css';
@import '@/assets/css/common.css';
/* If using the npm package, use the following lines instead of the one above */
/* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css'; */
/* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css'; */
</style>

<!-- Tiptap -->
<style lang="scss">
@import "@/assets/css/tiptap-editor.scss";
</style>

