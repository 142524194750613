<template>

  <div class="container-fluid">

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/">PPG</router-link></li>
        <li class="breadcrumb-item active"><router-link to="/home">Home</router-link></li>
        <li class="breadcrumb-item"><router-link to="/about">About</router-link></li>
      </ol>
    </nav>

    <button type="button" class="my-4 mr-5 btn btn-primary position-relative">
      Inbox
      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">12239<span class="visually-hidden">unread messages</span></span>
    </button>

    <h1>Welcome oho</h1>


    <h2>Exemple de composants</h2>

    <div class="row">

      <div class="col-6 col-sm-4 col-xl-3 my-4">
        <ppg-card
          title="Card 1"
          image="https://25.media.tumblr.com/tumblr_m2zcmltHbJ1qgwgfao1_250.jpg"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut"
          button-label="Continue"
          button-url="http://blog.hoachuck.biz"
        />
      </div>

      <div class="col-6 col-sm-4 col-xl-3 my-4">
        <ppg-card
          title="Card 2"
          image="https://24.media.tumblr.com/tumblr_lwr58sj5i91qzaz3ro1_250.jpg"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut"
          button-label="Continue"
          button-url="http://blog.hoachuck.biz"
        />
      </div>

      <div class="col-6 col-sm-4 col-xl-3 my-4">
        <ppg-card
          title="Card 3"
          image="https://25.media.tumblr.com/tumblr_m4jg8zE5nv1qjev1to1_250.jpg"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut"
          button-label="Continue"
          button-url="http://blog.hoachuck.biz"
        />
      </div>
    </div>




    <ppg-alert text="A simple primary alert—check it out!" alert-style="primary" />

    <ppg-alert text="A simple secondary alert—check it out!" alert-style="secondary" />

    <ppg-alert text="A simple success alert—check it out!" alert-style="success" />

    <ppg-alert text="A simple danger alert—check it out!" alert-style="danger" />

    <ppg-alert text="A simple warning alert—check it out!" alert-style="warning" />

    <div>
      <button type="button" class="btn btn-primary">Primary</button>&nbsp;
      <button type="button" class="btn btn-secondary">Secondary</button>&nbsp;
      <button type="button" class="btn btn-success">Success</button>&nbsp;
      <button type="button" class="btn btn-danger">Danger</button>&nbsp;
      <button type="button" class="btn btn-warning">Warning</button>&nbsp;
      <button type="button" class="btn btn-info">Info</button>&nbsp;
      <button type="button" class="btn btn-light">Light</button>&nbsp;
      <button type="button" class="btn btn-dark">Dark</button>&nbsp;
      <button type="button" class="btn btn-link">Link</button>&nbsp;
    </div>



  </div>
</template>

<script>

import PpgCard from '@/components/PpgCard';
import PpgAlert from '@/components/PpgAlert';

export default {
  name: 'HomePage',
  components: {
    PpgCard,
    PpgAlert,
  }
}
</script>

<style scoped>

</style>