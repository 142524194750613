<template>
  <div class="alert" :class="className" role="alert">{{ text }}</div>
</template>
<script>
export default {
  name: 'PpgCard',
  props:{
    alertStyle: String,
    text: String
  },
  computed: {
    className() {
      return "alert-" + this.alertStyle;
    }
  }
}
</script>
