<template>

  <div class="container-fluid">

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">PPG</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link to="/home">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/about">About</router-link>
        </li>
      </ol>
    </nav>


    <h1>About</h1>

    <div id="carouselExample" class="carousel slide">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <svg class="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400"
               xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: First slide"
               preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title>
            <rect width="100%" height="100%" fill="#f00"></rect>
            <text x="50%" y="50%" fill="#555" dy=".3em">First slide</text>
          </svg>
        </div>
        <div class="carousel-item">
          <svg class="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400"
               xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: First slide"
               preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title>
            <rect width="100%" height="100%" fill="#0f0"></rect>
            <text x="50%" y="50%" fill="#555" dy=".3em">Second slide</text>
          </svg>
        </div>
        <div class="carousel-item">
          <svg class="bd-placeholder-img bd-placeholder-img-lg d-block w-100" width="800" height="400"
               xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: First slide"
               preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title>
            <rect width="100%" height="100%" fill="#00f"></rect>
            <text x="50%" y="50%" fill="#555" dy=".3em">Second slide</text>
          </svg>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

  </div>

</template>

<script>
export default {
  name: 'AboutPage',
}
</script>