<template>
  <div class="card">
    <img :src="image" class="card-img-top" alt="">
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <p class="card-text">{{ text }}</p>
      <a :href="buttonUrl" class="btn btn-primary stretched-link">{{ buttonLabel }}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PpgCard',
  props:{
    image: String,
    title: String,
    text: String,
    buttonLabel: String,
    buttonUrl: String,
  }
}
</script>
