<template>

  <div id="app">
    <router-view />
  </div>
</template>


<style lang="css">
@import 'assets/css/themes/theme-minimal.css';
@import 'assets/css/common.css';
/* If using the npm package, use the following lines instead of the one above */
/* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css'; */
/* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css'; */
</style>

<!-- Tiptap -->
<style lang="scss">
  @import "assets/css/tiptap-editor.scss";
</style>

